<template>
  <div>
    <loading :can-cancel="true"></loading>
    <div id="login-container">
      <!-- login card section -->
      <div class="login-card">
        <!-- form container section -->
        <div class="form-container">
          <!-- form header section -->
          <div class="header">ثبت نام</div>
          <!-- end form header section -->
          <!-- login card image section -->
          <img class="liner" src="../../assets/images/Line-login.svg" alt="" />
          <!-- end login card image section -->
          <!-- form section -->
          <form class="login-form" @submit.prevent>
            <!-- input -->
            <label
              class="input-label"
              for=""
              :class="{ error: this.$store.state.status === 'error' }"
            >
              <img src="../../assets/images/mdi_account-circle-outline.svg" />
              <input
                @input="acceptNumber"
                v-model="mobile"
                type="text"
                name=""
                id=""
                placeholder="شماره موبایل"
              />
            </label>
            <div
              v-if="this.$store.state.status === 'error'"
              class="error-massage"
            >
              {{ this.$store.state.message }}
            </div>
            <!-- button -->
            <button @click="verifyMobile" class="btn-send-code">
              دریافت کد
            </button>
            <button @click="login" class="btn-register">ورود</button>
          </form>
          <!-- end form section -->
        </div>
        <!-- end form container section -->
        <img
          class="login-image"
          src="../../assets/images/auth-image.svg"
          height="502"
        />
      </div>
      <!-- end login card section -->
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      originalMobile: "",
      mobile: "",
      error: "",
      color: "#ffffff",
      size: "40px",
      loading: "RingLoader"
    };
  },
  components: {
    Loading
  },
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
    }
  },
  methods: {
    verifyMobile() {
      if (!this.originalMobile) {
        let message = "لطفا فیلد موبایل را وارد کنید";
        this.$store.dispatch("validationError", { message });
        return false;
      }
      if (this.originalMobile.length < 11) {
        let message = "شماره موبایل صحیح نمیباشد";
        this.$store.dispatch("validationError", { message });
        return false;
      }
      let mobile = this.originalMobile;
      this.$store
        .dispatch("mobileVerification", { mobile })
        .then(() => {
          this.$toast.success("کد فرستاده شد");
          this.$router.push("/register-code-verify");
        })
        .catch(error => console.log(error));
    },
    login() {
      this.$router.push("/login");
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      }
      if (evt.target.value.length > 10) {
        evt.preventDefault();
      }
      return true;
    },
    acceptNumber() {
      let x = this.mobile
        .replace(/\D/g, "")
        .match(/(\d{0,4})(\d{0,3})(\d{0,4})/);
      this.mobile = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    }
  }
};
</script>
<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(248, 241, 241);
  opacity: 1;
}

.loading {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.login-card {
  background: #1f3c88;
  border-radius: 20px;
  width: 1052px;
  height: 545px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.login-image {
  position: absolute;
  left: -14%;
  top: -18%;
  width: 70%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 6%;
  width: 88%;
  max-width: 400px;
}

.header {
  /*font-family: "Vazir Medium FD";*/
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-label {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: calc(100% - 50px);
  height: 30px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-label input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  width: calc(100% - 35px);
  padding-right: 5px;
  color: #ffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  direction: initial;
  text-align: right;
}

.input-label img {
  height: 20px;
  padding-right: 10px;
}

.liner {
  margin-bottom: 6px;
}

.btn-send-code {
  font-family: "Vazir Medium FD";
  border: none;
  background: #ffffff;
  border-radius: 10px;
  width: 285px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #070d59;
  cursor: pointer;
}

.btn-register {
  font-family: "Vazir Medium FD";
  margin-top: 5px;
  margin-bottom: 5px;
  /*border: none;*/
  width: 285px;
  height: 40px;
  background: #1f3c88;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  cursor: pointer;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-massage {
  margin-top: -15px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff6b6b;
  text-align: right;
  cursor: pointer;
  align-self: flex-start;
  margin-right: 25px;
}

/* responseiv section */
@media only screen and (max-width: 320px) {
  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
    margin-top: 10px;
  }

  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-image {
    display: none;
  }

  .header {
    font-size: 1.5em;
  }

  .liner {
    width: 100%;
  }

  .input-label {
    width: 80%;
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .btn-send-code {
    width: 70%;
    font-size: 16px;
  }

  .btn-register {
    width: 70%;
    font-size: 16px;
  }

  .error-massage {
    font-size: 0.5em;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  #login-container {
  }

  .form-container {
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
  }

  .header {
    font-size: 1.5em;
  }

  .input-label {
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .liner {
    width: 100%;
  }

  .btn-send-code {
    width: 80%;
    font-size: 1em;
  }

  .btn-register {
    width: 80%;
    font-size: 1em;
  }
}

@media only screen and (max-width: 1140px) {
  .login-image {
    left: -1%;
    top: -11%;
    width: 65%;
  }

  .login-card {
    /*padding: 0 70px;*/
  }

  #login-container {
    /*padding: 0 70px;*/
  }
}

@media only screen and (max-width: 960px) {
  .login-image {
    left: -11%;
    width: 60%;
    top: -8%;
  }
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }

  @media (max-width: 576px) {
    #login-container {
      padding: 0 10px;
    }
  }
}
</style>
